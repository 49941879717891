<template>
    <div class="text-center">
        <img :src="logoPath + logo" v-if="logo && isOnline" class="mx-auto" style="max-height:35px;"/>
        <span class="subtitle-1 pt-2 d-block" v-if="!isOnline">{{currentClient.name}}</span>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import constants from '../../helpers/constants';

export default {
    name: 'CompanyLogo',
    data: () => ({
        logoPath: constants.cloudinaryUrl,
        logo: null
    }),
    computed: {
        ...mapState('users', ['currentUser']),
        ...mapState('client', ['currentClient'])
    },
    created() {
        if (this.currentClient) {
            this.logo = this.currentClient.logo;
        } else {
            this.logo = localStorage.getItem('THEME_LOGO');
        }
    }
};
</script>
