<template>
    <div class="app-header">

        <v-navigation-drawer
            v-model="drawer"
            absolute
            temporary
        >
            <v-list class="pa-1">
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title v-if="currentUser">{{currentUser.firstName}} {{currentUser.lastName}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

            <v-list dense nav>
                <v-divider></v-divider>

                <v-list-item
                    v-for="item in staticPages"
                    :key="item.id"
                    link
                    light
                    :to="{name: 'static-detail', params: {id: item.id } }">
                    <v-list-item-icon>
                        <v-icon color="primary">chevron_right</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ item.title.en }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <div class="pa-5">
                    <v-btn color="primary" depressed rounded small light block @click="onSignOut">{{$t('basic.sign_out')}}</v-btn>
                </div>
            </v-list>
        </v-navigation-drawer>

        <v-layout wrap>
            <v-flex>
                <div class="u-flex justify-start">
                    <v-btn text icon color="primary" @click="drawer = true">
                        <v-icon>menu</v-icon>
                    </v-btn>
                </div>
            </v-flex>
            <v-flex grow>
                <company-logo />
            </v-flex>
            <v-flex>
                <div class="u-flex justify-end">
                    <v-btn text icon color="primary" :to="{name: 'settings'}">
                        <v-icon color="primary">
                            settings
                        </v-icon>
                    </v-btn>
                </div>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import {clearUserStorage} from '../../helpers/authHelpers';
import CompanyLogo from './CompanyLogo';

export default {
    name: 'AppHeaderWithLogo',
    components: {CompanyLogo},
    data() {
        return {
            drawer: false
        };
    },
    computed: {
        ...mapState('users', ['currentUser']),
        ...mapState('client', ['currentClient']),
        ...mapState('staticPages', ['staticPages'])
    },
    methods: {
        onSignOut() {
            clearUserStorage();
            this.$router.push('/');
        }
    }
};
</script>

<style scoped lang="scss">

    .app-header {
        padding: 10px 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
    }

</style>
