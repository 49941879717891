<template>
    <div>
        <app-header-with-logo/>
        <loading v-if="isLoading" />
        <v-tabs v-model="active" grow v-if="!isLoading">
            <v-tab href="#imo">
                IMO
            </v-tab>
            <v-tab href="#none-imo">
                Non-IMO
            </v-tab>
            <v-tab href="#other">
                Other
            </v-tab>
            <v-tab-item value="imo" transition="fade" reverse-transition="fade">
                <survey-card-list type="imo" v-if="active === 'imo'"/>
            </v-tab-item>
            <v-tab-item value="none-imo" transition="fade" reverse-transition="fade">
                <survey-card-list type="none-imo" v-if="active === 'none-imo'"/>
            </v-tab-item>
            <v-tab-item value="other" transition="fade" reverse-transition="fade">
                <survey-card-list type="other" v-if="active === 'other'"/>
            </v-tab-item>
        </v-tabs>
    </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex';
import {clearSurveysFromDB} from '../../../helpers/indexedDB';
import AppHeaderWithLogo from '../../../components/UI/AppHeaderWithLogo';
import Welcome from '../../../components/UI/Welcome';
import SurveyCardList from '../../../components/Surveys/SurveyCardList';

export default {
    name: 'Dashboard',
    data() {
        return {
            active: null,
            isLoading: false,
            isError: false
        };
    },
    computed: {
        ...mapState('surveys', ['surveyVersions', 'surveys'])
    },
    components: {
        AppHeaderWithLogo,
        Welcome,
        SurveyCardList
    },
    methods: {
        ...mapMutations('surveys', ['clearSurveyAnswers', 'clearSurveyFiles']),
        getSurveysByVersion() {
            clearSurveysFromDB();
            this.surveyVersions.forEach((survey, index) => {
                if (survey.version) {
                    this.$store.dispatch('surveys/getInitialSurveyByVersion', {
                        id: survey.id,
                        version: survey.version
                    }).then(() => {
                        // Done getting survey
                    }).catch(() => {
                        this.isError = true;
                    });
                }
            });
        },
        fetchSurveys() {
            this.isLoading = true;
            this.$store.dispatch('surveys/getSurveys').then(() => {
                this.isLoading = false;
                this.getSurveysByVersion();
            });
        },
        fetchNotifications() {
            this.$store.dispatch('notifications/getAllNotifications');
        },
        fetchStaticPages() {
            this.$store.dispatch('staticPages/getAllPages');
        },
        fetchSurveysFromStorage() {
            this.$store.dispatch('surveys/getSurveysFromStorage');
        },
        fetchStaticPagesFromStorage() {
            console.log('pages from storage');
            this.$store.dispatch('staticPages/getStaticPagesFromIndexedDB');
        },
        clearCurrentSurveyVersion() {
            localStorage.removeItem('CUR_SURVEY_VERSION');
        }
    },
    created() {
        if (this.isOnline) {
            this.fetchNotifications();
            this.fetchSurveys();
            this.fetchStaticPages();
        }
        if (!this.isOnline) {
            this.fetchSurveysFromStorage();
            this.fetchStaticPagesFromStorage();
        }
        this.clearCurrentSurveyVersion();
        this.clearSurveyFiles();
        this.clearSurveyAnswers();
    }
};
</script>
