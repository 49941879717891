<template>
    <div class="welcome">
        <div class="text-center py-4" v-if="currentUser">
            <div class="welcome__image">
                <v-avatar color="primary">
                    <span class="white--text">{{currentUser.firstName.substring(0, 1)}}{{currentUser.lastName.substring(0, 1)}}</span>
                </v-avatar>
            </div>
            <div class="welcome__message pt-2">
                <p class="mb-0">{{$t('basic.welcome', {firstName: currentUser.firstName})}}</p>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
    name: 'Welcome',
    computed: {
        ...mapState('users', ['currentUser'])
    }
};
</script>
