<template>
    <v-card flat class="survey-card u-rounded--small">
        <v-card-text class="text-center">
            <div v-if="survey.icon">
                <img :src="iconPath + survey.icon" alt="" style="max-width:80%;"/>
            </div>
            <div class="survey-card__title pt-2">
                <span :style="{'color': primaryColor}">{{getLocalizedValue(survey.title)}}</span>
            </div>
            <div class="pt-3">
                <v-btn color="primary" ripple depressed rounded small>{{$t('survey.start')}}</v-btn>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import constants from '../../helpers/constants';
import GetLocalizedValue from '@/mixins/GetLocalizedValue';
export default {
    name: 'SurveyCard',
    mixins: [GetLocalizedValue],
    props: {
        survey: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        iconPath: constants.cloudinaryUrlSurveyIcons,
        primaryColor: null
    }),
    created() {
        this.primaryColor = this.$vuetify.theme.themes.light.primary;
    }
};
</script>
