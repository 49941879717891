<template>
    <v-layout column class="survey-list px-4 pt-3">
        <!-- TODO: Refactor, make for effecient -->
        <template v-if="type === 'imo'">
            <v-flex xs6 class="px-2 mb-3" v-for="(survey, index) in surveysIMO" :key="index"
                    @click="gotoSurvey(survey)">
                <survey-card :survey="survey"/>
            </v-flex>
            <template v-if="surveysIMO.length === 0">
                <empty-state-surveys />
            </template>
        </template>
        <template v-if="type === 'none-imo'">
            <v-flex class="px-2 mb-3" v-for="(survey, index) in surveysNoneIMO" :key="index"
                    @click="gotoSurvey(survey)">
                <survey-card :survey="survey"/>
            </v-flex>
            <template v-if="surveysNoneIMO.length === 0">
                <empty-state-surveys />
            </template>
        </template>
        <template v-if="type === 'other'">
            <v-flex class="px-2 mb-3" v-for="(survey, index) in surveysOther" :key="index"
                    @click="gotoSurvey(survey)">
                <survey-card :survey="survey"/>
            </v-flex>
            <template v-if="surveysOther.length === 0">
                <empty-state-surveys />
            </template>
        </template>
    </v-layout>
</template>

<script>

import {mapGetters} from 'vuex';
import SurveyCard from './SurveyCard';
import EmptyStateSurveys from '../UI/EmptyStateSurveys';

export default {
    name: 'SurveyCardList',
    props: {
        type: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            isLoading: false
        };
    },
    computed: {
        ...mapGetters('surveys', ['surveysIMO', 'surveysNoneIMO', 'surveysOther'])
    },
    components: {
        EmptyStateSurveys,
        SurveyCard
    },
    methods: {
        gotoSurvey(survey) {
            this.$router.push({name: 'survey-detail', params: {surveyId: survey.id, surveyVersion: survey.version, mode: 'start-survey'}});
            localStorage.setItem('CUR_SURVEY_VERSION', survey.version);
        }
    }
};
</script>
