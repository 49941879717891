<template>
    <div class="text-center d-block pa-5" style="width: 100%">
        <v-icon size="30" color="error">error_outline</v-icon>
        <br />
        <small>{{$t('survey.empty')}}</small>
    </div>
</template>

<script>
export default {
    name: 'EmptyStateSurveys'
};
</script>
